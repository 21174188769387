export default defineNuxtRouteMiddleware((to, _from) => {
  const user = useUser();

  if (user.value.user !== null) {
    if (useIsActive() === false) {
      return;
    }

    if (user.value.user.role.enforce_tfa && !user.value.user.tfa_secret) {
      return navigateTo(`/setup-2fa?referrer=${encodeURIComponent(to.path)}`);
    }
  }
});
